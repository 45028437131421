import React, { Component } from "react"

import OUTOFBOXTHREEFIRST from "../../../images/OutOfbox/first.jpg"
import OUTOFBOXTHREESECOND from "../../../images/OutOfbox/second.jpg"
import OUTOFBOXTHREETHIRD from "../../../images/OutOfbox/third.jpg"

import OUTOFBOXTHREEFOURTH from "../../../images/OutOfbox/fourth.jpg"
import OUTOFBOXTHREEFIFTH from "../../../images/OutOfbox/fifth.jpg"
import CIRCLETICK from "../../../images/greencircletick.png"

import styled from "styled-components"
class BoxThree extends Component {
  render() {
    return (
      <MainBox>
        {/* Out Of The Box */}
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img
                src={OUTOFBOXTHREEFIRST}
                alt="one"
                width="100%"
                height="100%"
                // style="vertical-align:middle"
              />
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Just a few clicks to do the tricks
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Leverage our Out of the Box features to hit the ground running,
                easily onboard your people and set up processes. gain visibility
                across your business and start creating immediate results from
                day 1.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; KPIs, Sales processes, Automations - Generate and Mail
                quotations, notifications, dashboards and more
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Richly visualized dashboard with pre configured metrics
                presents data in real time, no more waiting for reports
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Real-time analytics, reporting to enhance customer
                engagement and conversions
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img
                src={OUTOFBOXTHREEFIRST}
                alt="one"
                width="100%"
                height="100%"
                // style="vertical-align:middle"
              />
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={OUTOFBOXTHREESECOND} alt="secondimage" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              {" "}
              <img src={OUTOFBOXTHREESECOND} alt="secondimage" width="100%" />
            </ImageBox>
          </BoxRight>

          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Start Selling from the get go!
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Getting started is easy, simply Drag and Drop to manage your
                opportunities, see progress in the sales process. Start creating
                new opportunities and take your business to new heights.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Gain insights on each customer for better engagements and
                improved win rates
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Pre-Configured Sales Processes & KPIs and metrics
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Focus more on the opportunity and closure, leave the
                administrative tasks to Korero.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
        </Box>
        <Spacer />

        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={OUTOFBOXTHREETHIRD} alt="thirdimage" width="100%" />{" "}
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Customize your fiscals as per your financial period
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Configure fiscal periods, quarters and more as per your needs.
                Dive deeper with our Out-of-the-Box financial reporting and gain
                detailed insights on progress and performance.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Configure your business accounting period including
                fiscal quarters
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Save time, access QoQ and YoY reports, compare
                performance with a single click
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Track real-time progress of opportunities and manage your
                people, sales funnel at a granular level for better customer
                experience.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img src={OUTOFBOXTHREETHIRD} alt="thirdimage" width="100%" />{" "}
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img src={OUTOFBOXTHREEFOURTH} alt="fourthimage" width="100%" />
            </ImageBox>
          </BoxRightMobile>
          <BoxRight>
            <ImageBox>
              <img src={OUTOFBOXTHREEFOURTH} alt="fourthimage" width="100%" />
            </ImageBox>
          </BoxRight>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Integrated Live currency conversions & reportings
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Easily manage and generate your reports and analytics for your
                geographically spread customer base, with a single click. Create
                quotations and commercials in multiple currencies for your
                customers in an instant. Tune your focus more on your
                opportunities and closure, as for the administrative tasks leave
                it to us.
              </BoxLeftContent1>
            </ContentWrapper>
          </BoxLeft>
        </Box>
        <Spacer />
        <Box>
          <BoxRightMobile>
            <ImageBox>
              <img
                src={OUTOFBOXTHREEFIFTH}
                alt="outofbox3firstimage"
                width="100%"
              />{" "}
            </ImageBox>
          </BoxRightMobile>
          <BoxLeft>
            <ContentWrapper>
              <BoxLeftMainHeader>
                Lead your business with insights
              </BoxLeftMainHeader>
              <BoxLeftContent1>
                {" "}
                Leading a team is not easy, but with Korero no more waiting for
                reports and excel sheets, keep an eye on your entire company's
                sales performance in real-time, in one place, at your
                fingertips. Monitor all your efforts and strategies with
                comprehensive dashboards and lead the way for your sales teams
                to generate more revenue.
              </BoxLeftContent1>

              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Access company’s sales KPIs in real time, all in one
                place.
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Richly visualized dashboard, across your sales processes,
                even past performance comparisions!
                <br />
              </BoxLeftContent>
              <BoxLeftContent>
                {" "}
                <img src={CIRCLETICK} alt="greencircletick" />
                &nbsp; Published in reporting currency for Leaders to make
                informed decisions and guide sales teams effectively.
              </BoxLeftContent>
            </ContentWrapper>
          </BoxLeft>
          <BoxRight>
            <ImageBox>
              <img
                src={OUTOFBOXTHREEFIFTH}
                alt="outofbox3firstimage"
                width="100%"
              />{" "}
            </ImageBox>
          </BoxRight>
        </Box>
        <Spacer />
      </MainBox>
    )
  }
}
export default BoxThree

const ContentWrapper = styled.div`
  /* border: 3px solid yellow; */
`

const MainBox = styled.div`
  padding: 40px;
  /* border: 1px solid red; */
  /* background: #eafaff; */
  @media screen and (max-width: 600px) {
    padding: 10px;
  }
`

const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    padding: 0px;
  }

  /* margin-top: 20px; */
`
const BoxLeft = styled.div`
  width: 45%;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
`

const BoxLeftMainHeader = styled.h2`
  font-family: "Roboto";
  font-size: 2.2em;
  font-weight: 500;
  text-align: left;
  margin: 0 auto;
  letter-spacing: -0.5px;
  color: #080612;
`
const BoxLeftContent1 = styled.p`
  font-family: "Roboto";
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  padding: 10px 0px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const BoxLeftContent = styled.p`
  font-family: "Roboto";
  font-weight: 100;
  padding: 0px 10px;
  font-size: 1.1em;
  text-align: left;
  color: #6b6a71;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`

const BoxRight = styled.div`
  width: 52%;
  /* padding: 0px 30px;
  margin-right: 70px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  @media screen and (max-width: 600px) {
    width: 100%;
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ImageBox = styled.div`
  border: 11px solid rgb(221, 221, 221);
  border-radius: 7px;
  /* height: 480px; */
  background-color: whitesmoke;
  overflow-x: hidden;
  @media screen and (max-width: 600px) {
    /* height: 220px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* height: 320px; */
    margin: 20px 10px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* height: 420px; */
    margin: 20px 10px;
  }
`
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 110px;
`
const BoxRightMobile = styled.div`
  @media only screen and (min-width: 1200px) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: block;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
