import React from "react"
import BoxOne from "./OutOfTheBoxNewPage/BoxOne"

import BoxTwo from "./OutOfTheBoxNewPage/BoxTwo"
import BoxFour from "./OutOfTheBoxNewPage/BoxFour"
import BoxThree from "./OutOfTheBoxNewPage/BoxThree"
import styled from "styled-components"
function Outoftheboxanalytics() {
  return (
    <div>
      <BoxOne />
      <BoxTwo />
      <Spacer />
      <BoxThree />
      <BoxFour />
    </div>
  )
}
export default Outoftheboxanalytics
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 30px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 30px;
  }
`
