import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Outoftheboxanalytics from "../modules/SubFetaures/Outoftheboxanalytics.js"

const outoftheboxuse = () => (
  <Layout>
    <SEO
      title="Out-of-the-box Use"
      description="Korero comes pre-configured with a set of processes, built-in analytical tools and number 
    of extensive features and functionalities that enables an organization from start-ups to enterprises with
     a large geo footprint to get started in minutes."
    />
    <Outoftheboxanalytics />

    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default outoftheboxuse
